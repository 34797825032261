import React, { useState } from 'react';
import ConfirmationPopup from './components/confirmation';
import { submitClientInfo } from './services/clientService';
import logo from './images/logo.png';
import './App.css';

const App = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
  });

  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await submitClientInfo(formData);
      if (response.status === 200) {
        // Handle successful response, e.g., show a confirmation popup
        setShowPopup(true);
      } else {
        // Handle other response status codes (if needed)
        console.log(response);
        alert('Unexpected response from the server.');
      }
      // Handle the response, e.g., show a confirmation popup

    } catch (error) {
      // Handle error, e.g., show an error message
      console.log(error);
      alert('Error submitting client information.');
    }
  };

  return (
    <div className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content">
      <div className=''>
        <a href='https://datecno.net'>
          <img src={logo} alt="Datecno" />
        </a>
        <h2 className="mb-4">SAC Pro registro de cliente</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nombre:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contactNumber">Celular:</label>
          <input
            type="tel"
            className="form-control"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group col-12">
          <div style={{ maxHeight: '200px', overflowX: 'auto' }}>
            <h5>Politica de tratamiento de datos.</h5>
            <br/>
            <br/>

            <p><strong>Fecha de entrada en vigor:</strong> 27 de julio de 2023</p>

            <p>Gracias por utilizar nuestro sistema. Esta Política de Privacidad tiene como objetivo explicar cómo recopilamos, utilizamos, protegemos y compartimos la información personal que recabamos a través de nuestro formulario de registro.</p>

            <h5>Estándares de Seguridad</h5>
            <p>En Datecno, estamos comprometidos con los más altos estándares de seguridad para proteger su información personal. Reconocemos que, aunque tomamos medidas significativas para salvaguardar sus datos, ningún sistema es completamente inmune a los ataques cibernéticos. Sin embargo, nuestra prioridad es garantizar la confidencialidad, integridad y disponibilidad de sus datos personales, y trabajamos constantemente en la mejora de nuestras prácticas de seguridad para mantenernos a la vanguardia en la protección de la información.</p>

            <h5>Información que recopilamos</h5>
            <p>Para poder brindarle nuestros servicios y notificaciones de WhatsApp y correo electrónico, recolectamos la siguiente información personal:</p>
            <ul>
              <li>Nombre completo</li>
              <li>Dirección de correo electrónico</li>
              <li>Número de teléfono (para notificaciones de WhatsApp)</li>
              <li>Otros datos personales necesarios para brindar nuestros servicios.</li>
            </ul>

            <h5>Uso de la información</h5>
            <p>Utilizamos la información recopilada con los siguientes fines:</p>
            <ul>
              <li>Enviar notificaciones importantes relacionadas con nuestros servicios a través de WhatsApp y correo electrónico.</li>
              <li>Comunicarnos con usted sobre actualizaciones del sistema, cambios en las políticas y otros asuntos relacionados con su cuenta.</li>
              <li>Realizar copias de seguridad y mantener la integridad de los datos almacenados en nuestro sistema.</li>
              <li>Mejorar y personalizar nuestros servicios.</li>
            </ul>

            <h5>Protección de la información</h5>
            <p>Tomamos en serio la protección de su información personal. Hemos implementado medidas técnicas, administrativas y físicas adecuadas para proteger su información de pérdida, uso indebido, acceso no autorizado, divulgación o alteración. Solo aquellos empleados o proveedores de servicios que necesiten acceso a su información personal para brindarle nuestros servicios tendrán permiso para hacerlo y estarán sujetos a estrictas obligaciones de confidencialidad.</p>

            <h5>Compartir información</h5>
            <p>No compartiremos su información personal con terceros, excepto en las siguientes circunstancias:</p>
            <ul>
              <li>Con su consentimiento explícito.</li>
              <li>Si es necesario para cumplir con las leyes y regulaciones aplicables o en respuesta a una solicitud legal válida.</li>
              <li>Para proteger nuestros derechos, privacidad, seguridad o propiedad, o los de otros usuarios o terceros.</li>
              <li>Si hay una venta, fusión, adquisición, reorganización o transferencia de activos de nuestra empresa, en cuyo caso se le notificará con antelación.</li>
            </ul>

            <h5>Acceso a la información</h5>
            <p>Usted tiene el derecho de acceder, corregir o eliminar su información personal en cualquier momento. Si desea ejercer alguno de estos derechos o tiene alguna pregunta o inquietud sobre nuestra Política de Privacidad, no dude en ponerse en contacto con nosotros a través de la siguiente dirección de correo electrónico: <a href="mailto:desarrolloweb@datecno.net">desarrolloweb@datecno.net</a>.</p>

            <h5>Consentimiento</h5>
            <p>Al proporcionar su información personal a través de nuestro formulario de registro, usted acepta los términos y condiciones de esta Política de Privacidad y consiente el uso y procesamiento de su información de acuerdo con lo descrito anteriormente.</p>

            <h5>Cambios en la Política de Privacidad</h5>
            <p>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio entrará en vigor inmediatamente después de la publicación en nuestro sitio web. Le recomendamos que revise periódicamente esta página para estar al tanto de cualquier actualización.</p>

            <p>Gracias por confiar en nosotros para la protección y manejo de su información personal. Si tiene alguna pregunta o inquietud, no dude en comunicarse con nosotros.</p>

            <p>Atentamente,</p>
            <p>El equipo de Datecno</p>

          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Registrarse
        </button>
      </form>
      {showPopup && (
        <ConfirmationPopup
          name={formData.name}
          email={formData.email}
          phone={formData.contactNumber}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default App;
