import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_SERVER_URL; // Replace this with your actual backend API URL

// Replace 'username' and 'password' with the actual credentials for your backend
const username = 'admin';
const password = 'P3rr3o2023';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Replace with your backend API URL
  auth: {
    username,
    password,
  },
});

// Function to submit client information to the backend
export const submitClientInfo = async (clientData) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/api/licence`, clientData);
    console.log(response);
    return response; // The response data from the backend
  } catch (error) {
    console.log(error);
    throw new Error(error); // Handle error
  }
};